import React from 'react';
import { Hiscore, skillFields } from '../../types/types';
import {
  calculateExpGains,
  calculateLevelOrRankDifferences,
  stringToUpperCaseFirstLetter,
} from '../../utils/helperFunctions';
import { Loading } from '../Loading';
import { Table } from 'react-bootstrap';

interface Props {
  hiscores: Hiscore[];
  loadingPlayer: boolean;
  selectedSkill: String;
  setSelectedSkill: (selectedSkill: String) => void;
}

const SkillsTable: React.FC<Props> = ({
  hiscores,
  selectedSkill,
  setSelectedSkill,
  loadingPlayer,
}: Props) => {
  const firstHiscore: Hiscore = hiscores[0];
  const latestHiscore: Hiscore = hiscores[hiscores.length - 1];

  return (
    <div data-testid="skillsTable">
      {loadingPlayer && <Loading />}
      <Table bordered hover variant="dark" className="skillsTable">
        <thead>
          <tr>
            <th>Skill</th>
            <th>Level</th>
            <th>Rank</th>
            <th>Exp</th>
            <th>Exp Gains</th>
          </tr>
        </thead>
        <tbody>
          {skillFields.map((skill) => {
            return (
              <tr
                key={skill}
                className={skill === selectedSkill ? 'selectedSkillRow' : ''}
                onClick={() => setSelectedSkill(skill)}
              >
                <td>{stringToUpperCaseFirstLetter(skill)}</td>
                <td>
                  {Math.floor(latestHiscore.levels[0][skill]).toLocaleString(
                    'en-US',
                  )}
                  {calculateLevelOrRankDifferences(
                    latestHiscore.levels[0][skill] -
                      firstHiscore.levels[0][skill],
                  )}
                </td>
                <td>
                  {Math.floor(latestHiscore.ranks[0][skill]).toLocaleString(
                    'en-US',
                  )}
                  {calculateLevelOrRankDifferences(
                    firstHiscore.ranks[0][skill] -
                      latestHiscore.ranks[0][skill],
                  )}
                </td>
                <td>
                  {Math.floor(
                    latestHiscore.experiences[0][skill],
                  ).toLocaleString('en-US')}
                </td>
                <td>
                  {calculateExpGains(
                    latestHiscore.experiences[0][skill] -
                      firstHiscore.experiences[0][skill],
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default SkillsTable;
