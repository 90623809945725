import React, { useEffect, useState } from 'react';
import { Loading } from '../../components/Loading';
import { Leaderboard, TimePeriod } from '../../types/types';
import { stringToUpperCaseFirstLetter } from '../../utils/helperFunctions';
import { LeaderboardCard } from '../../components/LeaderboardCard';
import {
  fetchCurrentLeaderboards,
  fetchRecordsLeaderboards,
} from '../../services/api';
import { TimePeriodSelection } from '../../components/TimePeriodSelection';
import { Button, Tab, Tabs } from '@mui/material';
import { DateSelection } from '../../components/DateSelection';
import { Dayjs } from 'dayjs';

const Leaderboards: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [leaderboards, setLeaderboards] = useState<Leaderboard | undefined>(
    undefined,
  );
  const [showDateSelection, setShowDateSelection] = useState<boolean>(true);
  const [tabValue, setTabValue] = React.useState(0);
  const [timePeriod, setTimePeriod] = useState<TimePeriod>('day');
  const [amountOfRows, setAmountOfRows] = useState<number>(5);

  useEffect(() => {
    handlefetchCurrentLeaderboardsByTimePeriod('day');
  }, []);

  const handleSetTimePeriod = (newTimePeriod: TimePeriod) => {
    setTimePeriod(newTimePeriod);
    tabValue === 0
      ? handlefetchCurrentLeaderboardsByTimePeriod(newTimePeriod)
      : handlefetchRecordsLeaderboardsByTimePeriod(newTimePeriod);
  };

  const handlefetchCurrentLeaderboardsByTimePeriod = async (
    timePeriod: TimePeriod,
  ) => {
    try {
      if (timePeriod === 'day') {
        setShowDateSelection(true);
      } else {
        setShowDateSelection(false);
      }
      setLoading(true);
      const result = await fetchCurrentLeaderboards(timePeriod);
      setLeaderboards(result);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handlefetchCurrentLeaderboardsByDate = async (date: Dayjs) => {
    try {
      setLoading(true);
      const result = await fetchCurrentLeaderboards('day', date);
      setLeaderboards(result);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handlefetchRecordsLeaderboardsByTimePeriod = async (
    timePeriod: TimePeriod,
  ) => {
    try {
      setLoading(true);
      const result = await fetchRecordsLeaderboards(timePeriod);
      setLeaderboards(result);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleTabChange = (
    event: React.SyntheticEvent,
    newTabValue: number,
  ) => {
    event.preventDefault();
    setAmountOfRows(5);
    setTimePeriod('day');
    if (newTabValue === 0) {
      handlefetchCurrentLeaderboardsByTimePeriod('day');
    } else {
      setShowDateSelection(false);
      handlefetchRecordsLeaderboardsByTimePeriod('day');
    }
    setTabValue(newTabValue);
  };

  return (
    <div className="leaderboardsPage">
      <h1 className="title">Leaderboards</h1>
      <div className="leaderboardTabContainer">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="leaderboard tabs"
        >
          <Tab label="Current Top" aria-label="Current Top" {...a11yProps(0)} />
          <Tab label="Records" aria-label="Records" {...a11yProps(1)} />
        </Tabs>
      </div>
      <div className="leaderboardsPageTimePeriodSelection">
        <TimePeriodSelection
          timePeriod={timePeriod}
          handleFetch={handleSetTimePeriod}
        />
      </div>
      {showDateSelection && tabValue !== 1 && (
        <div className="leaderboardsPageDateSelection">
          <DateSelection handleFetch={handlefetchCurrentLeaderboardsByDate} />
        </div>
      )}
      {loading ? (
        <Loading />
      ) : (
        <div>
          {amountOfRows === 5 ? (
            <Button onClick={() => setAmountOfRows(10)}>TOP 10</Button>
          ) : (
            <Button onClick={() => setAmountOfRows(5)}>TOP 5</Button>
          )}
          {leaderboards && (
            <div className="leaderboardCardsContainer">
              {Object.keys(leaderboards).map((skill) => {
                return (
                  <LeaderboardCard
                    key={skill}
                    skill={stringToUpperCaseFirstLetter(skill)}
                    leaderboardData={leaderboards[skill as keyof Leaderboard]}
                    selectedTab={tabValue === 0 ? 'currentTop' : 'records'}
                    amountOfRows={amountOfRows}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Leaderboards;
