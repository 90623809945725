import React, { useState } from 'react';
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import { exportData } from '../../services/api';
import { DataFormat, Player } from '../../types/types';

interface Props {
  player: Player;
}

const ExportData: React.FC<Props> = ({ player }) => {
  const [showExportDataModal, setShowExportDataModal] =
    useState<boolean>(false);

  const handleOpenExportDataModal = () => setShowExportDataModal(true);
  const handleCloseExportDataModal = () => setShowExportDataModal(false);

  const handleExportData = async (dataFormat: DataFormat) => {
    handleCloseExportDataModal();
    await exportData(player, dataFormat);
  };

  const exportDataModalstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#1e1e1e',
    color: 'white',
    p: 4,
    textAlign: 'center',
  };

  return (
    <div className="exportDataContainer">
      <Grid item className="buttonContainer">
        <Button
          className="lightTextBtn"
          variant="outlined"
          color="primary"
          onClick={handleOpenExportDataModal}
        >
          Export Data
        </Button>
      </Grid>

      <Modal
        open={showExportDataModal}
        onClose={handleCloseExportDataModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={exportDataModalstyle}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Download a file containing all your hiscores data
          </Typography>
          <Grid item className="buttonContainer dataExportModalButtonContainer">
            <Button
              className="lightTextBtn"
              variant="outlined"
              color="primary"
              onClick={() => handleExportData('csv')}
            >
              CSV
            </Button>
            <Button
              className="lightTextBtn"
              variant="outlined"
              color="primary"
              onClick={() => handleExportData('xlsx')}
            >
              XLSX
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default ExportData;
