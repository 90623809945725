import React, { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

interface Props {
  handleFetch: (date: Dayjs) => void;
}

const DateSelection: React.FC<Props> = ({ handleFetch }: Props) => {
  const [date, setDate] = useState<Dayjs | null>(dayjs().add(-1, 'day'));

  const handleSetDate = (newDate: Dayjs | null) => {
    if (newDate) {
      const newDateStartOfDay = newDate.startOf('day');
      setDate(newDateStartOfDay);
      handleFetch(newDateStartOfDay);
    }
  };

  const disableDates = (date: Dayjs): boolean => {
    const yesterday = dayjs().add(-1, 'day');
    const startLimit = dayjs('2024-01-02');
    return date.isBefore(startLimit) || date.isAfter(yesterday);
  };

  return (
    <div className="dateSelectContainer" data-testid="dateSelectContainer">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={date}
          shouldDisableDate={disableDates}
          onChange={(newDate) => handleSetDate(newDate)}
          closeOnSelect={true}
        />
      </LocalizationProvider>
    </div>
  );
};

export default DateSelection;
