import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './pages/home/home.css';
import './pages/playerPage/playerPage.css';
import './pages/playersPage/playersPage.css';
import './pages/comparePlayers/comparePlayers.css';
import './pages/leaderboards/leaderboards.css';
import './pages/about/about.css';
import './App.css';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Home from './pages/home/Home';
import PlayerPage from './pages/playerPage/PlayerPage';
import ComparePlayers from './pages/comparePlayers/ComparePlayers';
import { NavBar } from './components/NavBar';
import PlayersPage from './pages/playersPage/PlayersPage';
import Leaderboards from './pages/leaderboards/Leaderboards';
import About from './pages/about/About';

function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/players" element={<PlayersPage />} />
        <Route path="/players/:id" element={<PlayerPage />} />
        <Route path="/compare-players" element={<ComparePlayers />} />
        <Route path="/leaderboards" element={<Leaderboards />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
