import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Player, TimePeriod } from '../../types/types';
import { TimePeriodSelection } from '../../components/TimePeriodSelection';
import { LineChart } from '../../components/LineChart';
import { SkillsTable } from '../../components/SkillsTable';
import { fetchPlayer } from '../../services/api';
import { DateSelection } from '../../components/DateSelection';
import { Dayjs } from 'dayjs';
import { ExportData } from '../../components/ExportData';

const PlayerPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [player, setPlayer] = useState<Player>();
  const [loadingPlayer, setLoadingPlayer] = useState<boolean>(true);
  const [selectedSkill, setSelectedSkill] = useState<String>('overall');
  const [showDateSelection, setShowDateSelection] = useState<boolean>(true);
  const [timePeriod, setTimePeriod] = useState<TimePeriod>('day');

  useEffect(() => {
    handleFetchPlayerByTimePeriod('day');
    // eslint-disable-next-line
  }, []);

  const handleSetTimePeriod = (newTimePeriod: TimePeriod) => {
    setTimePeriod(newTimePeriod);
    handleFetchPlayerByTimePeriod(newTimePeriod);
  };

  const handleFetchPlayerByTimePeriod = async (timePeriod: TimePeriod) => {
    if (id) {
      try {
        if (timePeriod === 'day') {
          setShowDateSelection(true);
        } else {
          setShowDateSelection(false);
        }
        setLoadingPlayer(true);
        const result = await fetchPlayer(id, timePeriod);
        setPlayer(result);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingPlayer(false);
      }
    }
  };

  const handleFetchPlayerByDate = async (date: Dayjs) => {
    if (id) {
      try {
        setLoadingPlayer(true);
        const result = await fetchPlayer(id, 'day', date);
        setPlayer(result);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingPlayer(false);
      }
    }
  };

  const sortedHiscores = (player: Player) => {
    return player.hiscores.sort(
      (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    );
  };

  return (
    <div>
      <div className="playerPage">
        {!loadingPlayer && !player && (
          <div className="loading">
            <p>Player not found</p>
          </div>
        )}
        {player && (
          <>
            <h1 className="title">{player.name}</h1>
            <div className="playerCard">
              <TimePeriodSelection
                timePeriod={timePeriod}
                handleFetch={handleSetTimePeriod}
              />
              {showDateSelection && (
                <div className="playerPageDateSelection">
                  <DateSelection handleFetch={handleFetchPlayerByDate} />
                </div>
              )}
              {player && <ExportData player={player} />}
              {player.hiscores?.length > 0 ? (
                <>
                  <SkillsTable
                    hiscores={sortedHiscores(player)}
                    loadingPlayer={loadingPlayer}
                    selectedSkill={selectedSkill}
                    setSelectedSkill={setSelectedSkill}
                  />
                  {selectedSkill && (
                    <LineChart
                      selectedSkill={selectedSkill}
                      hiscores={sortedHiscores(player)}
                    />
                  )}
                </>
              ) : (
                <div className="textContainer">
                  No hiscores found for selected time period
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PlayerPage;
