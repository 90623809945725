import { Dayjs } from 'dayjs';
import {
  DataFormat,
  Leaderboard,
  Player,
  PlayerData,
  PlayerWithoutHiscores,
  TimePeriod,
} from '../types/types';

export const fetchPlayers = async (): Promise<PlayerWithoutHiscores[]> => {
  try {
    const response = await fetch('/api/players', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch players');
    }

    return await response.json();
  } catch (error) {
    throw new Error('Failed to fetch players');
  }
};

export const fetchPlayer = async (
  playerId: String,
  timePeriod: TimePeriod,
  date?: Dayjs,
): Promise<Player> => {
  try {
    const url = date
      ? `/api/players/${playerId}?timePeriod=${timePeriod}&date=${date.format(
          'YYYY-MM-DDTHH:mm:ssZ',
        )}`
      : `/api/players/${playerId}?timePeriod=${timePeriod}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch player data');
    }

    return await response.json();
  } catch (error) {
    throw new Error('Failed to fetch player data');
  }
};

export const createPlayer = async (name: string): Promise<Player> => {
  if (!name) {
    throw new Error('Player name can not be empty');
  }
  if (name.length > 12) {
    throw new Error('Max length of a player name is 12');
  }
  try {
    const response = await fetch('/api/players', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name }),
    });

    if (!response.ok) {
      if (response.status === 400) {
        const errorDetail = await response.json();
        throw new Error(errorDetail.detail);
      }
      throw new Error('Failed to create player');
    }

    return (await response.json()) as Player;
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    }
    throw new Error('Failed to create player');
  }
};

export const findPlayer = async (name: string): Promise<Player> => {
  try {
    const response = await fetch(`/api/player/${name}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      if (response.status === 404) {
        const errorDetail = await response.json();
        throw new Error(errorDetail.detail);
      }
      throw new Error('Failed to find player');
    }

    return (await response.json()) as Player;
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    }
    throw new Error('Failed to find player');
  }
};

export const comparePlayers = async (
  player1: string,
  player2: string,
): Promise<PlayerData> => {
  try {
    const response = await fetch(
      `/api/compare-players?player1=${player1}&player2=${player2}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok) {
      throw new Error('Failed to compare players');
    }

    return await response.json();
  } catch (error) {
    throw new Error('Failed to compare players');
  }
};

export const fetchCurrentLeaderboards = async (
  timePeriod: TimePeriod,
  date?: Dayjs,
): Promise<Leaderboard> => {
  try {
    const url = date
      ? `/api/current-leaderboards?timePeriod=${timePeriod}&date=${date.format(
          'YYYY-MM-DDTHH:mm:ssZ',
        )}`
      : `/api/current-leaderboards?timePeriod=${timePeriod}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch current leaderboards');
    }

    return await response.json();
  } catch (error) {
    throw new Error('Failed to fetch current leaderboards');
  }
};

export const fetchRecordsLeaderboards = async (
  timePeriod: TimePeriod,
): Promise<Leaderboard> => {
  try {
    const url = `/api/records-leaderboards?timePeriod=${timePeriod}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch records leaderboards');
    }

    return await response.json();
  } catch (error) {
    throw new Error('Failed to fetch records leaderboards');
  }
};

export const exportData = async (player: Player, dataFormat: DataFormat) => {
  try {
    const response = await fetch(
      `/api/export-data?playerId=${player.id}&dataFormat=${dataFormat}`,
      {
        method: 'GET',
      },
    );

    if (!response.ok) {
      throw new Error('Failed to export data');
    }

    const contentDisposition = response.headers.get('Content-Disposition');
    let filename = `hiscores_${player.name}.${dataFormat}`;
    if (contentDisposition) {
      const matches = contentDisposition.match(/filename="?(.+)"?/);
      if (matches && matches.length > 1) {
        filename = matches[1];
      }
    }

    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error('Failed to export data', error);
    throw error;
  }
};
