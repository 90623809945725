import React from 'react';
import { LeaderboardData, LeaderboardTab } from '../../types/types';
import { Card, CardContent, Link, Typography } from '@mui/material';
import { calculateLevelOrRankDifferences } from '../../utils/helperFunctions';

interface Props {
  skill: string;
  leaderboardData: LeaderboardData[];
  selectedTab: LeaderboardTab;
  amountOfRows?: number;
}

const LeaderboardCard: React.FC<Props> = ({
  skill,
  leaderboardData,
  selectedTab,
  amountOfRows = 5,
}) => {
  const isOneDayApart = (startDate: Date, endDate: Date): boolean => {
    const normalizedStartDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
    );
    const normalizedEndDate = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
    );
    const diffInMs = Math.abs(
      normalizedEndDate.getTime() - normalizedStartDate.getTime(),
    );
    const diffInDays = diffInMs / (24 * 60 * 60 * 24);

    return diffInDays >= 0 && diffInDays <= 1;
  };

  const formatHiscoreDateRange = (leaderboard: LeaderboardData): string => {
    const startDate = new Date(leaderboard.start_date);
    const endDate = new Date(leaderboard.end_date);

    const dateFormat: Intl.DateTimeFormatOptions = {
      month: '2-digit',
      day: '2-digit',
    };

    const formattedStartDate = startDate.toLocaleDateString(
      'en-US',
      dateFormat,
    );
    const formattedEndDate = endDate.toLocaleDateString('en-US', dateFormat);

    if (isOneDayApart(startDate, endDate)) {
      return formattedStartDate;
    }

    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  return (
    <Card variant="elevation" className="leaderboardCard">
      <CardContent>
        <Typography className="leaderboardCardTitle" variant="h6" gutterBottom>
          <img
            src={`images/${skill.toLocaleLowerCase()}.png`}
            alt={`${skill} skill icon`}
            className="skillIcon"
          />
          {skill}
        </Typography>
        <div className="leaderboardStatContainer">
          <table className="leaderboardCardTable">
            <thead>
              <tr>
                <th></th>
                <th className="leaderboardCardTableNameHeader"></th>
                <th></th>
                <th>Levels</th>
                <th>Ranks</th>
              </tr>
            </thead>
            <tbody className="leaderboardCardTableBody">
              {leaderboardData
                .slice(0, amountOfRows)
                .map((leaderboard, index) => {
                  return (
                    <tr key={`${skill}-${leaderboard.player_name}-${index}`}>
                      <td>{index + 1}</td>
                      <td className="leaderboardCardTableNameColumn">
                        <Typography variant="body2" className="leaderboardName">
                          <span className="leaderboardCardTableName">
                            {leaderboard.player_name !== '-' ? (
                              <Link
                                href={`/players/${leaderboard.player_id}`}
                                className="link"
                              >
                                {leaderboard.player_name}
                              </Link>
                            ) : (
                              '-'
                            )}
                          </span>
                        </Typography>
                      </td>
                      <td>
                        {leaderboard.exp_gain > 0
                          ? `${Math.floor(leaderboard.exp_gain).toLocaleString(
                              'en-US',
                            )}`
                          : '-'}
                        {selectedTab === 'records' &&
                          leaderboard.player_name !== '-' && (
                            <span className="recordDate">
                              {formatHiscoreDateRange(leaderboard)}
                            </span>
                          )}
                      </td>
                      <td className="leaderboardCardTableDifferenceColumn">
                        {leaderboard.player_name !== '-' &&
                          calculateLevelOrRankDifferences(
                            leaderboard.levels_gain,
                          )}
                      </td>
                      <td className="leaderboardCardTableDifferenceColumn">
                        {leaderboard.player_name !== '-' &&
                          calculateLevelOrRankDifferences(
                            leaderboard.ranks_gain,
                          )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </CardContent>
    </Card>
  );
};

export default LeaderboardCard;
