import React, { useEffect, useState } from 'react';
import { PlayerWithoutHiscores } from '../../types/types';
import { fetchPlayers } from '../../services/api';
import { Link } from '@mui/material';
import { Loading } from '../../components/Loading';

const PlayersPage: React.FC = () => {
  const [players, setPlayers] = useState<PlayerWithoutHiscores[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await fetchPlayers();
      setPlayers(result);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="playersPage">
      {loading && <Loading />}
      {!loading && (
        <div className="container">
          <h1 className="smallTitle">{`Tracked players (${players.length})`}</h1>
          <ul className="list">
            {players.map((player) => {
              return (
                <li key={player.id}>
                  <Link href={`/players/${player.id}`} className="link">
                    {player.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default PlayersPage;
