export const stringToUpperCaseFirstLetter = (word: String) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const calculateExpGains = (difference: number) => {
  const localeDifference = difference.toLocaleString('en-US');
  if (difference < 0) {
    return <span className="redText">{localeDifference}</span>;
  }
  if (difference > 0) {
    return <span className="greenText">+{localeDifference}</span>;
  }
};

export const calculateLevelOrRankDifferences = (difference: number) => {
  const localeDifference = difference.toLocaleString('en-US');
  if (difference < 0) {
    return <span className="redText difference">{localeDifference}</span>;
  }
  if (difference > 0) {
    return <span className="greenText difference">+{localeDifference}</span>;
  }
};
