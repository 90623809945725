import React, { ChangeEvent, useState } from 'react';
import { comparePlayers } from '../../services/api';
import { Table } from 'react-bootstrap';
import { ComparePlayersData, skillFields } from '../../types/types';
import { stringToUpperCaseFirstLetter } from '../../utils/helperFunctions';
import { Alert, Button, Grid, Link, TextField } from '@mui/material';
import { Loading } from '../../components/Loading';

const ComparePlayers: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [player1InputValue, setPlayer1InputValue] = useState<string>('');
  const [player2InputValue, setPlayer2InputValue] = useState<string>('');
  const [playerData1, setPlayerData1] = useState<
    ComparePlayersData | undefined
  >(undefined);
  const [playerData2, setPlayerData2] = useState<
    ComparePlayersData | undefined
  >(undefined);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handlePlayer1InputValueChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setPlayer1InputValue(event.target.value);
  };

  const handlePlayer2InputValueChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setPlayer2InputValue(event.target.value);
  };

  const handleComparePlayersSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    if (player1InputValue && player2InputValue) {
      setLoading(true);
      const response = await comparePlayers(
        player1InputValue,
        player2InputValue,
      );
      setPlayerData1(response.player1);
      setPlayerData2(response.player2);
      setPlayer1InputValue('');
      setPlayer2InputValue('');
      setLoading(false);
    } else {
      setErrorMessage(`Please fill both fields`);
      setTimeout(() => {
        setErrorMessage('');
      }, 5000);
    }
  };

  const redOrGreenRow = (player1Exp: number, player2Exp: number) => {
    if (player1Exp === player2Exp) {
      return '';
    }
    return player1Exp > player2Exp ? 'green' : 'red';
  };

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <>
          <div className="compareplayerPage">
            <p>
              Compare stats of two players from{' '}
              <Link
                className="link"
                href="https://rsc.vet/hiscores/preservation"
                target="_blank"
              >
                preservation server
              </Link>
            </p>
            <div className="formContainer">
              <form onSubmit={handleComparePlayersSubmit} className="form">
                <Grid container alignItems="center" spacing={2}>
                  <Grid item className="inputContainer">
                    <TextField
                      name="playerName1"
                      label="Player name 1"
                      value={player1InputValue}
                      onChange={handlePlayer1InputValueChange}
                      variant="filled"
                      className="textField"
                      size="small"
                      sx={{
                        color: 'rgb(96 165 250)',
                        backgroundColor: 'white',
                      }}
                    />
                  </Grid>
                  <Grid item className="inputContainer">
                    <TextField
                      name="playerName2"
                      label="Player name 2"
                      value={player2InputValue}
                      onChange={handlePlayer2InputValueChange}
                      variant="filled"
                      className="textField"
                      size="small"
                      sx={{
                        color: 'rgb(96 165 250)',
                        backgroundColor: 'white',
                      }}
                    />
                  </Grid>
                  <Grid item className="buttonContainer">
                    <Button variant="outlined" color="primary" type="submit">
                      Compare
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>

            {!loading && playerData1 && playerData2 ? (
              <div className="tableContainer">
                <div className="tablePlayer1">
                  <h3 className="smallTitle">{playerData1.name}</h3>
                  <Table variant="dark" className="comparePlayersTable">
                    <thead>
                      <tr>
                        <th>Skill</th>
                        <th>Level</th>
                        <th>Rank</th>
                        <th>Exp</th>
                      </tr>
                    </thead>
                    <tbody>
                      {skillFields.map((skill) => {
                        return (
                          <tr key={skill}>
                            <td
                              className={redOrGreenRow(
                                playerData1.experiences[skill],
                                playerData2.experiences[skill],
                              )}
                            >
                              {stringToUpperCaseFirstLetter(skill)}
                            </td>

                            <td>
                              {Math.floor(
                                playerData1.levels[skill],
                              ).toLocaleString('en-US')}
                            </td>

                            <td>
                              {Math.floor(
                                playerData1.ranks[skill],
                              ).toLocaleString('en-US')}
                            </td>

                            <td>
                              {Math.floor(
                                playerData1.experiences[skill],
                              ).toLocaleString('en-US')}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>

                <div className="tablePlayer2">
                  <h3 className="smallTitle">{playerData2.name}</h3>
                  <Table variant="dark" className="comparePlayersTable">
                    <thead>
                      <tr>
                        <th>Skill</th>
                        <th>Level</th>
                        <th>Rank</th>
                        <th>Exp</th>
                      </tr>
                    </thead>
                    <tbody>
                      {skillFields.map((skill) => {
                        return (
                          <tr key={skill}>
                            <td
                              className={redOrGreenRow(
                                playerData2.experiences[skill],
                                playerData1.experiences[skill],
                              )}
                            >
                              {stringToUpperCaseFirstLetter(skill)}
                            </td>

                            <td>
                              {Math.floor(
                                playerData2.levels[skill],
                              ).toLocaleString('en-US')}
                            </td>

                            <td>
                              {Math.floor(
                                playerData2.ranks[skill],
                              ).toLocaleString('en-US')}
                            </td>

                            <td>
                              {Math.floor(
                                playerData2.experiences[skill],
                              ).toLocaleString('en-US')}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            ) : null}
          </div>
          {errorMessage && (
            <Alert severity="error" variant="filled" className="alert">
              {errorMessage}
            </Alert>
          )}
        </>
      )}
    </>
  );
};

export default ComparePlayers;
