import { Link } from '@mui/material';
import React from 'react';

const About: React.FC = () => {
  return (
    <div className="aboutPage">
      <h1 className="smallTitle">About</h1>
      <div className="aboutInfoContainer">
        <p>
          Champions Guild is an entirely fan-made, third-party open-source
          application to track hiscores on{' '}
          <Link href="https://rsc.vet" className="link" target="_blank">
            OpenRSC
          </Link>{' '}
          preservation server. The OSRS hiscore tracker{' '}
          <Link
            href="https://www.wiseoldman.net"
            className="link"
            target="_blank"
          >
            Wise Old Man
          </Link>{' '}
          has been a great inspirer if not a model for this. Even though having
          no official affiliation with OpenRSC, it is approved by the Mod/Admin
          team and is totally safe to use. Feel free to contact me, Lord Jolt,
          in-game or through the OpenRSC Discord if you have any questions,
          suggestions, or similar inquiries.
        </p>
        <p>
          This tracker was originally created for a week-long Agility event on
          April 2023 hosted by player named Url. It was never used for that, but
          I kept it tracking my overall experience for about seven months. Later
          I found out that the master miner Zooer had been manually tracking
          hiscores for an extended period. I figured I could expand this and put
          it in a real use since I already had a solid base.
        </p>
        <p>Champions Guild has been online since January 1, 2024.</p>
        <p>
          <Link
            href="https://gitlab.com/zilleville/champions-guild"
            className="link"
            target="_blank"
          >
            GitLab source code
          </Link>
        </p>
        <img
          src="/images/fighting.png"
          alt="Fighting skill icon"
          className="skillIconNoMarginRight"
        />
        Thanks
        <img
          src="/images/fighting.png"
          alt="Fighting skill icon"
          className="skillIconNoMarginRight"
        />
        <ul>
          <li>
            Zooer for giving out lots of ideas and being my personal rubber duck
          </li>
          <li>Apsolutnul for making the awesome skill icons</li>
          <li>
            kRiStOf for linking this to his{' '}
            <Link
              href="https://spinachroll.com"
              className="link"
              target="_blank"
            >
              Spinach Roll
            </Link>{' '}
            kalculators site
          </li>
        </ul>
      </div>

      <div className="changeLog">
        <h3 className="smallTitle">Change Log</h3>

        <div className="changeLogContainer">
          <p>24-Jul-2024</p>
          <ul>
            <li>
              Toggle to show TOP 5 / TOP 10 on both Current Top and Records
              Leaderboards tabs
            </li>
          </ul>
        </div>

        <div className="changeLogContainer">
          <p>28-Apr-2024</p>
          <ul>
            <li>
              Show the differences in levels and ranks on the Leaderboards, just
              like the player page already has. Please let me know if this
              update sucks or needs some adjustments, and I'll see what I can
              do.
            </li>
          </ul>
        </div>

        <div className="changeLogContainer">
          <p>07-Apr-2024</p>
          <ul>
            <li>
              Players' hiscores data can be exported from the player page as a
              CSV or XLSX file
            </li>
          </ul>
        </div>

        <div className="changeLogContainer">
          <p>23-Mar-2024</p>
          <ul>
            <li>
              I managed to optimize the Leaderboards load times a lot. Normally
              you won't see the effect since it does it only first time and gets
              saved to a cache after that. Heroku restarts the app every 24
              hours so the first one to open the leaderboards after that has to
              wait the calculations.
            </li>
            <li>
              I was getting bit worried since it was getting so slow already
              with this player base and only 3 months in. By the end of the year
              I bet I have to come up with new optimizations also since the data
              gets so big. The Day Record tab is the most brutal one to
              calculate. Also the update process itself is huge bottleneck
              still, it takes about 15 minutes with this 230 players. If OpenRSC
              would serve an API endpoint to fetch them all with one request it
              would be so much faster.
            </li>
          </ul>
        </div>

        <div className="changeLogContainer">
          <p>01-Mar-2024</p>
          <ul>
            <li>
              I removed 49 players from the tracking list who had not gained any
              experience during their entire tracking period, which ranged from
              20 to over 50 days. It's interesting to stalk the high rank
              players, but propably no need for players last logged in like 3
              years ago.
            </li>
            <li>
              I probably won't delete players with records unless they are
              caught cheating or something. However, removing inactive players
              does make the daily update process and overall use of the app
              faster, given there are fewer players to track and data to
              calculate.
            </li>
          </ul>
        </div>

        <div className="changeLogContainer">
          <p>16-Feb-2024</p>
          <ul>
            <li>
              Update crashed again. This time 10 seconds timeout exceeded when
              getting hiscores for a player. I raised the timeout to 30 seconds.
              Not sure if it was slow on rsc.vet part or mine, but since this
              error happened first time I would guess rsc.vet.
            </li>
          </ul>
        </div>

        <div className="changeLogContainer">
          <p>15-Feb-2024</p>
          <ul>
            <li>
              Now also Current Top Leaderboards are cached except other than
              current dates for Day. App may restart occassionally, so the first
              time doing any of the searches will load them and store in cache
              so next page loads will happen fast.
            </li>
          </ul>
        </div>

        <div className="changeLogContainer">
          <p>13-Feb-2024</p>
          <ul>
            <li>
              The update process for the data of February 12th crashed and I had
              to delete all hiscores for that day. That will leave couple days
              empty gap and break players week/month strikes. I'm sorry for
              that.
            </li>
            <li>
              The crash seemed to be caused by a timeout when people loaded the
              very heavy record leaderboards query in midst of an update
              process. Since Records change only once in 24 hours, they are now
              calculated to cache only once right after the update process. This
              way the Records tab will now be very fast, since it returns
              beforehand calculated value.
            </li>
          </ul>
        </div>

        <div className="changeLogContainer">
          <p>01-Feb-2024</p>
          <ul>
            <li>
              Records tab for Leaderboards. It shows all time records for
              selected time period. Only one entry per player.
            </li>
            <li>Removed 'All time' option from time period selection</li>
            <li>
              Champions Guild has been online for whole one month now, having
              135 tracked players
            </li>
          </ul>
        </div>

        <div className="changeLogContainer">
          <p>24-Jan-2024</p>
          <ul>
            <li>
              I heard some ppl take screenshots of previous dates to compare
              current leaderboards. I added date selector to Leaderboards and
              Player pages when Day time period selected
            </li>
            <li>
              Reduced the sleep interval between player hiscore updates down to
              2 seconds to make update process faster
            </li>
          </ul>
        </div>

        <div className="changeLogContainer">
          <p>17-Jan-2024</p>
          <ul>
            <li>
              Added skill icons to Leaderboards page made by our belowed
              community member Apsolutnul
            </li>
          </ul>
        </div>

        <div className="changeLogContainer">
          <p>16-Jan-2024</p>
          <ul>
            <li>More consistent and new color styles</li>
            <li>
              Current Top tab to Leaderboards, as a hint for Records is coming
              when I'm able to find out how to do it
            </li>
            <li>
              Display the top 5 Leaderboards instead of the previously shown top
              3
            </li>
            <li>
              Reduced the sleep interval between player hiscore updates down to
              5 seconds
            </li>
            <li>Created this About page</li>
          </ul>
        </div>

        <div className="changeLogContainer">
          <p>13-Jan-2024</p>
          <ul>
            <li>Optimized database queries and added indexes</li>
            <li>
              Find player form since over 80 players being tracked already
            </li>
            <li>Links to player profiles from Leaderboards</li>
          </ul>
        </div>

        <div className="changeLogContainer">
          <p>09-Jan-2024</p>
          <ul>
            <li>
              Optimized embarrassing terrible and slow Leaderboards page
              database query from 2-3s to 300-500ms
            </li>
            <li>
              Reduced sleep time between fetching players hiscores from 15s to
              10s. Idea is not to burden rsc.vet too much, but with increasing
              amount of players the whole update is taking over 15 minutes
            </li>
            <li>Prevented adding players with a skill total under 150</li>
          </ul>
        </div>

        <div className="changeLogContainer">
          <p>07-Jan-2024</p>
          <ul>
            <li>
              Semi official launch day when I linked this to OpenRSC discord and
              some 50 players were added right away
            </li>
            <li>
              Leaderboards page which shows current top experience gains for
              Day/Week/Month/Year/All time
            </li>
            <li>
              Bunch of hotfixes since people found ways to add not wanted inputs
              through form
            </li>
          </ul>
        </div>

        <div className="changeLogContainer">
          <p>05-Jan-2024</p>
          <ul>
            <li>
              New domain, championsguild.fi, in use, replacing the default
              randomly generated string URL provided by Heroku
            </li>
          </ul>
        </div>

        <div className="changeLogContainer">
          <p>04-Jan-2024</p>
          <ul>
            <li>Compare players page and style refactoring</li>
            <li>
              Lots of small fixes and style refactorings between 01 and 04
            </li>
          </ul>
        </div>

        <div className="changeLogContainer">
          <p>01-Jan-2024</p>
          <ul>
            <li>Initial deploy to Heroku</li>
            <li>
              Ability to add a player and see personal statistics for every
              skill
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default About;
