import React, { useState, useEffect } from 'react';

const Loading: React.FC = () => {
  const [leftEmoji, setLeftEmoji] = useState<string>('');
  const [rightEmoji, setRightEmoji] = useState<string>('');
  const fullEmoji = "';-;'";
  const [isBuilding, setIsBuilding] = useState<boolean>(true);
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isBuilding) {
        if (index <= fullEmoji.length) {
          setLeftEmoji(fullEmoji.substring(0, index));
          setRightEmoji(fullEmoji.substring(fullEmoji.length - index));
          setIndex(index + 1);
        } else {
          setIsBuilding(false);
          setIndex(fullEmoji.length - 1);
        }
      } else {
        if (index >= 0) {
          setLeftEmoji(fullEmoji.substring(0, index));
          setRightEmoji(fullEmoji.substring(fullEmoji.length - index));
          setIndex(index - 1);
        } else {
          setIsBuilding(true);
          setIndex(1);
        }
      }
    }, 200);

    return () => clearInterval(interval);
  }, [index, isBuilding]);

  return (
    <div className="loading">
      <p>
        <span className="emoji">{leftEmoji}</span> Picking up cabbages...{' '}
        <span className="emoji">{rightEmoji}</span>
      </p>
    </div>
  );
};

export default Loading;
