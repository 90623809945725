import React, { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createPlayer, findPlayer } from '../../services/api';
import { Loading } from '../../components/Loading';
import { Alert, Button, Grid, Link, TextField } from '@mui/material';
import { ErrorMessage } from '../../types/types';

const Home: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [addPlayerInputValue, setAddPlayerInputValue] = useState<string>('');
  const [findPlayerInputValue, setFindPlayerInputValue] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();

  const handleAddPlayerInputValueChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setAddPlayerInputValue(event.target.value);
  };

  const handleFindPlayerInputValueChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setFindPlayerInputValue(event.target.value);
  };

  const handleAddPlayerSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    setLoading(true);
    try {
      const createdPlayer = await createPlayer(addPlayerInputValue);
      if (createdPlayer.id) {
        navigate(`/players/${createdPlayer.id}`);
      } else {
        setErrorMessage(`Failed to create player ${addPlayerInputValue}`);
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message.includes('Player name already registered')) {
          setErrorMessage(ErrorMessage.ALREADY_REGISTERED(addPlayerInputValue));
        } else if (
          error.message.includes(
            'Player name not found on rsc.vet preservation server',
          )
        ) {
          setErrorMessage(
            ErrorMessage.NOT_FOUND_ON_SERVER(addPlayerInputValue),
          );
        } else if (
          error.message.includes(
            'You can only add a player if you have at least 150 total level',
          )
        ) {
          setErrorMessage(ErrorMessage.MINIMUM_TOTAL_LEVEL());
        } else if (error.message.includes('Player name can not be empty')) {
          setErrorMessage(ErrorMessage.EMPTY_NAME());
        } else if (
          error.message.includes('Max length of a player name is 12')
        ) {
          setErrorMessage(ErrorMessage.MAX_LENGTH_EXCEEDED());
        } else {
          setErrorMessage(ErrorMessage.GENERIC_ERROR(addPlayerInputValue));
        }
      } else {
        setErrorMessage(ErrorMessage.GENERIC_ERROR(addPlayerInputValue));
      }
    } finally {
      setAddPlayerInputValue('');
      setLoading(false);
      setTimeout(() => {
        setErrorMessage('');
      }, 5000);
    }
  };

  const handleFindPlayerSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    setLoading(true);
    try {
      const foundPlayer = await findPlayer(findPlayerInputValue);
      if (foundPlayer.id) {
        navigate(`/players/${foundPlayer.id}`);
      } else {
        setErrorMessage(`Player ${findPlayerInputValue} not found`);
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message.includes('Player not found')) {
          setErrorMessage(`Player ${findPlayerInputValue} not found`);
        } else {
          setErrorMessage(`Failed to find player ${findPlayerInputValue}`);
        }
      } else {
        setErrorMessage(`Failed to find player ${findPlayerInputValue}`);
      }
    } finally {
      setFindPlayerInputValue('');
      setLoading(false);
      setTimeout(() => {
        setErrorMessage('');
      }, 5000);
    }
  };

  return (
    <>
      <div className="home">
        {loading && <Loading />}
        {!loading && (
          <>
            <div className="infoText">
              <p>
                Add a player to track the{' '}
                <Link className="link" href="https://rsc.vet" target="_blank">
                  OpenRSC
                </Link>{' '}
                preservation server hiscores
              </p>
              <p>
                Update process starts alphabetically every midnight UTC time and
                takes over 15 minutes to finish
              </p>
            </div>
            <div className="formContainer">
              <form
                onSubmit={handleAddPlayerSubmit}
                className="form"
                data-testid="addPlayerForm"
              >
                <Grid container alignItems="center" spacing={2}>
                  <Grid item className="inputContainer">
                    <TextField
                      name="addPlayer"
                      label="Player name"
                      value={addPlayerInputValue}
                      onChange={handleAddPlayerInputValueChange}
                      variant="filled"
                      className="textField"
                      size="small"
                      sx={{
                        color: 'rgb(96 165 250)',
                        backgroundColor: 'white',
                      }}
                    />
                  </Grid>
                  <Grid item className="buttonContainer">
                    <Button
                      className="lightTextBtn"
                      variant="outlined"
                      color="primary"
                      type="submit"
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </form>

              <p>Find a player from Champions Guild</p>
              <form
                onSubmit={handleFindPlayerSubmit}
                className="form"
                data-testid="findPlayerForm"
              >
                <Grid container alignItems="center" spacing={2}>
                  <Grid item className="inputContainer">
                    <TextField
                      name="findPlayer"
                      label="Player name"
                      value={findPlayerInputValue}
                      onChange={handleFindPlayerInputValueChange}
                      variant="filled"
                      className="textField"
                      size="small"
                      sx={{
                        color: 'rgb(96 165 250)',
                        backgroundColor: 'white',
                      }}
                    />
                  </Grid>
                  <Grid item className="buttonContainer">
                    <Button
                      className="lightTextBtn"
                      variant="outlined"
                      color="primary"
                      type="submit"
                    >
                      Find
                    </Button>
                  </Grid>
                </Grid>
              </form>

              {errorMessage && (
                <Alert severity="error" variant="filled" className="alert">
                  {errorMessage}
                </Alert>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Home;
