import { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import type { Hiscore, HiscoreBase, HiscoreData } from '../../types/types';
import { stringToUpperCaseFirstLetter } from '../../utils/helperFunctions';

interface LineChartProps {
  selectedSkill: String;
  hiscores: Hiscore[];
}

const LineChart: React.FC<LineChartProps> = ({ hiscores, selectedSkill }) => {
  const chartCanvas = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart | null>(null);

  useEffect(() => {
    if (!chartCanvas.current) return;

    const ctx = chartCanvas.current.getContext('2d');

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const chartData = hiscores.flatMap((hiscore: Hiscore) =>
      hiscore.experiences.map(
        (experience: HiscoreData) =>
          experience[selectedSkill as keyof HiscoreBase],
      ),
    );
    const labels = hiscores.map((hiscore: Hiscore) =>
      new Date(hiscore.created_at).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    );

    chartInstance.current = new Chart<'line'>(ctx!, {
      type: 'line',
      data: {
        labels,
        datasets: [
          {
            label: 'Total exp',
            data: chartData,
            borderColor: 'cyan',
          },
        ],
      },
      options: {
        animation: false,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: `${stringToUpperCaseFirstLetter(selectedSkill)} exp`,
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const tooltipLabels = [];

                let label = context.dataset.label || '';
                label += `: ${context.parsed.y.toLocaleString('en-US')}`;

                let gainedExpLabel = 'From the day before: +';
                context.dataIndex === 0
                  ? (gainedExpLabel +=
                      context.parsed.y -
                      hiscores[0].experiences[0][
                        selectedSkill as keyof HiscoreBase
                      ])
                  : (gainedExpLabel += (
                      context.parsed.y -
                      parseInt(
                        context.dataset.data[context.dataIndex - 1].toString(),
                      )
                    ).toLocaleString('en-US'));

                tooltipLabels.push(label);
                tooltipLabels.push(gainedExpLabel);

                return tooltipLabels;
              },
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: '#e8d800',
            },
          },
          y: {
            ticks: {
              color: '#e8d800',
            },
          },
        },
      },
    });
  }, [selectedSkill, hiscores]);

  return <canvas ref={chartCanvas} id="expChart" />;
};

export default LineChart;
