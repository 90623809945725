import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import { TimePeriod } from '../../types/types';

interface Props {
  timePeriod: TimePeriod;
  handleFetch: (timePeriod: TimePeriod) => void;
}

const TimePeriodSelection: React.FC<Props> = ({
  timePeriod,
  handleFetch,
}: Props) => {
  const handleSetTimePeriod = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value as TimePeriod;
    handleFetch(selectedValue);
  };

  return (
    <div
      className="timePeriodSelectContainer"
      data-testid="timePeriodSelectContainer"
    >
      <Form.Select
        value={timePeriod}
        onChange={handleSetTimePeriod}
        className="timePeriodSelect"
        aria-label="Select time period"
      >
        <option value="day">Day</option>
        <option value="week">Week</option>
        <option value="month">Month</option>
        <option value="year">Year</option>
      </Form.Select>
    </div>
  );
};

export default TimePeriodSelection;
